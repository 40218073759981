import request from '@/utils/request';
// 基本信息
export const base = {
    archives_num: '', // 诊疗卡号
    scoring_num: '', // scoring_num
    name: '', // 被评人姓名
    examiner_name: '', // 主试姓名
    gender: '', // 性别
    is_minorities: '', // 是否为少数名族
    hand_habit: '', // 用手习惯
    exam_address: '', // 测试地点
    born_date: '', // 出生日期
    real_age: '', // 实际年龄
    remark: '' // 备注说明
}

export const simple = {
    edition: '5',
    test_date: "", // 测试日期
    real_age: "", // 实际年龄
    score_bd: '', // 积木
    score_si: '', // 类同
    score_ds: '', // 背数
    score_cd: '', // 译码
    score_mr: '', // 矩阵推理
    score_co: '', // 理解
    simple_sub_test_num : 4,
    comparison_basis: '1', // 比较基础
    confidence_probability: 95, // 置信区间
    significance_level: 0.05, // 显著性水平
    computing_method: '', // 计算方法
    supplement_type: '' // 补充方法
}

export const simpleRule = {
    'complete.test_date': [
        {required: true, message: '请选择测试日期', trigger: 'change'},
    ],
    'complete.real_age': [
        {required: true, message: '请计算实际年龄', trigger: 'change'},
    ],
    'complete.confidence_probability': [
        {required: true, message: '请选择置信区间', trigger: 'change'},
    ],
    'complete.significance_level': [
        {required: true, message: '请选择统计显著性水平', trigger: 'change'},
    ],
    'complete.comparison_basis': [
        {required: true, message: '请选择比较基础', trigger: 'change'},
    ],
    'complete.computing_method': [
        {required: false, message: '请选择计算方法', trigger: 'change'},
    ],
    'complete.supplement_type': [
        {required: false, message: '请选择补充方式', trigger: 'change'},
    ]
}