<template>
  <div class="main-container">
    <div class="main-header">
      <div class="logo-box">
        <img src="/images/logo.png" alt="">
      </div>
      <div class="name-box">韦氏儿童智力量表第四版中文版计分系统</div>
      <topMenu :num="2"></topMenu>
    </div>
    <div class="main-body column">
      <div class="header">
        <div class="title-box"><img src="/images/input-header-icon.png" alt=""><span class="name">简版</span></div>
      </div>
      <div class="body">
        <div class="de" style="margin:0 0 40px 0;font-size: 14px"><span
            style="color: #832C75;font-weight: bold;margin-right: 10px">原始数据</span>ps: <span
            style="color: #832C75">*</span>为必填项
        </div>
        <a-form-model ref="form" :model="formModel" :rules="formRules" autoComplete="off" :label-col="labelCol"
                      :wrapper-col="wrapperCol" labelAlign="left">
          <a-row>
            <a-col :span="11">
              <a-form-model-item label="测试日期" prop="simple.test_date">
                <a-date-picker :disabled="id ? true : false" style="width: 100%" class="cu-input1"
                               @change="changeParentTestTime" format="YYYY-MM-DD"
                               v-model="formModel.simple.test_date"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="11">
              <a-form-model-item label="实际年龄" prop="simple.real_age">
                <a-input class="cu-input1" disabled placeholder="选择测试日期后将自动计算"
                         v-model="formModel.simple.real_age"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <div class="line1" style="margin-bottom: 40px;margin-top: 10px"></div>
          <a-row>
            <a-col :span="11">
              <a-row>
                <a-col>
                  <div class="right-title">比较类型</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="置信区间" prop="simple.confidence_probability">
                    <a-select v-model="formModel.simple.confidence_probability" placeholder="请选择"
                              style="width: 260px;" dropdownClassName="cu-select">
                      <a-select-option value="90">90%</a-select-option>
                      <a-select-option value="95">95%</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="分测验" prop="simple_sub_test_num">
                    <div class="flex" style="margin-top: 10px">
                      <a-radio-group v-model="formModel.simple.simple_sub_test_num" @change="handleRadio">
                        <a-radio :value="4">四个分测验</a-radio>
                        <a-radio :value="5">五个分测验</a-radio>
                        <a-radio :value="6">六个分测验</a-radio>
                      </a-radio-group>
                    </div>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-col>

            <a-col :span="11">
              <p class=""> 注：</p>
              <p> “四个分测验”量表分数总和由“类同”、“背数”、 “译码”及“矩阵推理”的量表分数相加得出。</p>

              <p>“五个分测验”量表分数总和由“积木”、“类同”、“译码”、 “矩阵推理”及“理解”的量表分数相加得出。</p>

              <p>“六个分测验”量表分数总和由“积木”、“类同”、“背数”、 “译码”、“矩阵推理”及“理解”的量表分数相加得出。</p>
            </a-col>
            <!-- <a-form-model-item label="统计显著性水平" prop="simple.significance_level">
        <a-select v-model="formModel.simple.significance_level" placeholder="请选择(强项/弱项)" style="width: 260px" dropdownClassName="cu-select">
          <a-select-option value="0.05">0.05</a-select-option>
          <a-select-option value="0.15">0.15</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-col>
  </a-row>
  <a-row>
    <a-col :span="24">
      <a-form-model-item label="比较基础" prop="simple.comparison_basis">
        <a-select v-model="formModel.simple.comparison_basis" placeholder="请选择比较基础" style="width: 260px" dropdownClassName="cu-select">
          <a-select-option value="1">全样本</a-select-option>
          <a-select-option value="2">能力水平</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-col>
  </a-row> -->

            <!-- <a-col :span="11" class="right-box" style="padding-left: 40px">
  <a-row>
    <a-col :span="24">
      <div style="font-size: 12px;color: #832C75;margin-bottom: 20px;padding-left: 5px">存在无效的原始分数时</div>
      <a-form-model-item label="计算方法" prop="simple.computing_method">
        <a-radio-group name="radioGroup" v-model="formModel.simple.computing_method" @change="changeCM">
          <a-radio :value="1">替代分测验</a-radio>
          <a-radio :value="2">比例估算法</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-col>
  </a-row>
  <a-row>
    <a-col :span="24">
      <div style="font-size: 12px;color: #832C75;margin-bottom: 20px;padding-left: 5px">找虫缺失时,选择以下补充</div>
      <a-form-model-item label="补充方式" prop="simple.supplement_type">
        <a-radio-group name="radioGroup" v-model="formModel.simple.supplement_type">
          <a-radio :value="1">划消</a-radio>
          <a-radio :value="2">动物译码</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-col>
  </a-row>
</a-col> -->
          </a-row>
          <div class="line1"></div>
          <a-row>
            <a-col :span="11">
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="积木" prop="simple.score_bd"  v-if="!scoreBDBool">
                    <div class="flex">
                      <a-input-number @focus="numFocus" v-model="formModel.simple.score_bd" prop="score_bd"
                                      class="cu-input1 input-number-100" :min="0" :max="68"/>
                      <span class="num-de"><0-68></span>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="类同" prop="simple.score_si">
                    <div class="flex">
                      <a-input-number @focus="numFocus" v-model="formModel.simple.score_si" prop="score_si"
                                      class="cu-input1 input-number-100" :min="0" :max="44"/>
                      <span class="num-de"><0-44></span>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="背数" prop="simple.score_ds" v-if="!scoreDSBool">
                    <div class="flex">
                      <a-input-number @focus="numFocus" v-model="formModel.simple.score_ds" prop="score_ds"
                                      class="cu-input1 input-number-100" :min="0" :max="38"/>
                      <span class="num-de"><0-38></span>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="译码" prop="simple.score_cd">
                    <div class="flex">
                      <a-input-number @focus="numFocus" v-model="formModel.simple.score_cd" prop="score_cd"
                                      class="cu-input1 input-number-100" :min="0" :max="scoreCdMax"/>
                      <span class="num-de">{{scoreCdTip}}</span>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="矩阵推理" prop="simple.score_mr">
                    <div class="flex">
                      <a-input-number @focus="numFocus" v-model="formModel.simple.score_mr" prop="score_mr"
                                      class="cu-input1 input-number-100" :min="0" :max="35"/>
                      <span class="num-de"><0-35></span>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="理解" prop="simple.score_co" v-if="!scoreCOBool">
                    <div class="flex">
                      <a-input-number @focus="numFocus" v-model="formModel.simple.score_co" prop="score_co"
                                      class="cu-input1 input-number-100" :min="0" :max="42"/>
                      <span class="num-de"><0-42></span>
                    </div>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-col>

          </a-row>
          <div style="border-bottom: 1px solid #f8f8f8;margin: 40px 0">&nbsp;</div>

          <div class="line1" style="margin-bottom: 40px;margin-top: 10px"></div>
          <div style="display: flex;align-items: center;justify-content: center">
            <!--            <div class="btn sh" @click="goSelectAge"><img src="/images/btn-left-icon.png" alt="" style="height: 12px;margin-right: 5px"><span>返回选择页面</span></div>-->
            <div class="btn" @click="actionData">{{ id != '' ? '编辑并生成报告' : '保存并生成报告' }}</div>

          </div>
        </a-form-model>
      </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
import {Save, Detail, Update} from '../../commonApi';
import {base, simple, simpleRule} from "../simple-field";
import {message} from "ant-design-vue";
import * as Api from "../base-info/api";
import moment from "moment";

export default {
  data() {
    return {
      scoreCdTip:'<0-119>',
      scoreCdMax:119,
      scoreBDBool: false,
      scoreCOBool: false,
      scoreDSBool: false,
      labelCol: {span: 8, style: {color: '#7E8589', fontWeight: '500'}},
      wrapperCol: {span: 14},
      formModel: {
        base: Object.assign({}, base),
        simple: Object.assign({}, simple),
      },
      formRules: Object.assign({
        "simple.score_bd": [
          { required: true, message: '请输入积木分数', trigger: 'change' },
        ],
        "simple.score_si": [
          { required: true, message: '请输入类同分数', trigger: 'change' },
        ],
        "simple.score_ds": [
          { required: true, message: '请输入背数分数', trigger: 'change' },
        ],
        "simple.score_co": [
          { required: true, message: '请输入理解分数', trigger: 'change' },
        ],
        "simple.score_cd": [
          { required: true, message: '请输入译码分数', trigger: 'change' },
        ],
        "simple.score_mr": [
          { required: true, message: '请输入矩阵推理分数', trigger: 'change' },
        ]
      }, {...simpleRule}),
      isUnit: 1, // 是否关联
      bindListVisible: false,
      id: ''
    }
  },
  mounted() {

    // 编辑
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    } else {
      this.formModel.base = JSON.parse(window.localStorage.getItem('base_info'));
    }
    if (this.$route.query && this.$route.query.source) {
      this.source = this.$route.query.source;
    }
    this.scoreBDBool = false
    this.scoreCOBool = false
    this.scoreDSBool = false
    if (this.formModel.simple.simple_sub_test_num === 4) {
      this.formModel.simple.score_bd = null;
      this.formModel.simple.score_co = null;
      this.scoreBDBool = true
      this.scoreCOBool = true
    } else if (this.formModel.simple.simple_sub_test_num === 5) {
      this.formModel.simple.score_ds = null;
      this.scoreDSBool = true;
    }
  },
  methods: {
    changeHx() {
      // if (/^[0-9]+$/.test(this.formModel.simple.score_car) && /^[0-9]+$/.test(this.formModel.simple.score_cas)) {
      //   this.formModel.simple.score_ca = parseInt(this.formModel.simple.score_car) + parseInt(this.formModel.simple.score_cas)
      // } else {
      //   this.formModel.simple.score_ca = '';
      // }
    },
    handleRadio() {
      this.scoreBDBool = false
      this.scoreCOBool = false
      this.scoreDSBool = false
      if (this.formModel.simple.simple_sub_test_num === 4) {
        this.formModel.simple.score_bd = null;
        this.formModel.simple.score_co = null;
        this.scoreBDBool = true
        this.scoreCOBool = true
      } else if (this.formModel.simple.simple_sub_test_num === 5) {
        this.formModel.simple.score_ds = null;
        this.scoreDSBool = true;
      }
    },

    async getDetail() {
      const res = await Detail({id: this.id});
      if (res && res.code == 0) {
        this.formModel.simple = Object.assign({}, res.wiscAssessment);
        this.formModel.base = {
          id: res.wiscAssessment.id,
          archives_num: res.wiscAssessment.archives_num,
          scoring_num: res.wiscAssessment.scoring_num,
          name: res.wiscAssessment.name,
          examiner_name: res.wiscAssessment.examiner_name,
          simple_sub_test_num: res.wiscAssessment.simple_sub_test_num,
          is_minorities: res.wiscAssessment.is_minorities,
          hand_habit: res.wiscAssessment.hand_habit,
          exam_address: res.wiscAssessment.exam_address,
          born_date: res.wiscAssessment.born_date,
          remark: res.wiscAssessment.remark,
          grade: res.wiscAssessment.grade,
          parent_culture: res.wiscAssessment.parent_culture,
        }
        this.formModel.simple.computing_method = parseInt(this.formModel.simple.computing_method)
        this.formModel.simple.supplement_type = parseInt(this.formModel.simple.supplement_type)
        this.formModel.simple.comparison_basis = parseInt(this.formModel.simple.comparison_basis)
        this.formModel.simple.confidence_probability = parseInt(this.formModel.simple.confidence_probability)
        this.scoreBDBool = false
        this.scoreCOBool = false
        this.scoreDSBool = false
        if (this.formModel.simple.simple_sub_test_num === 4) {
          this.formModel.simple.score_bd = null;
          this.formModel.simple.score_co = null;
          this.scoreBDBool = true
          this.scoreCOBool = true
        } else if (this.formModel.simple.simple_sub_test_num === 5) {
          this.formModel.simple.score_ds = null;
          this.scoreDSBool = true;
        }
      }
    },
    goSelectAge() {
      this.$router.push({path: '/select-age'})
    },

    numFocus() {
      // this.$refs["form"].clearValidate('simple.computing_method');
      // this.$refs["form"].clearValidate('simple.supplement_type');
      // this.formRules['simple.computing_method'][0]['required'] = false;
      // this.formRules['simple.supplement_type'][0]['required'] = false;

    },
    changeComputingMethod() {
      // if (/^[0-9]+$/.test(this.formModel.simple.score_bd)
      //     && /^[0-9]+$/.test(this.formModel.simple.score_in)
      //     && /^[0-9]+$/.test(this.formModel.simple.score_mr)
      //     && /^[0-9]+$/.test(this.formModel.simple.score_bs)
      //     && /^[0-9]+$/.test(this.formModel.simple.score_pm)
      //     && /^[0-9]+$/.test(this.formModel.simple.score_si)
      //     && /^[0-9]+$/.test(this.formModel.simple.score_bs)) {
      //   this.formModel.simple.computing_method = undefined;
      // }
    },
    async actionData() {
      // this.changeComputingMethod()
      // if (!this.formModel.simple.score_bd) {
      //   if (this.formModel.simple.score_bd != '0') {
      //     this.formRules['simple.computing_method'][0]['required'] = true;
      //   }
      // }
      // if (!this.formModel.simple.score_in) {
      //   if (this.formModel.simple.score_in != '0') {
      //     this.formRules['simple.computing_method'][0]['required'] = true;
      //   }
      // }
      // if (!this.formModel.simple.score_mr) {
      //   if (this.formModel.simple.score_mr != '0') {
      //     this.formRules['simple.computing_method'][0]['required'] = true;
      //   }
      // }
      // if (!this.formModel.simple.score_bs) {
      //   if (this.formModel.simple.score_bs != '0') {
      //     this.formRules['simple.computing_method'][0]['required'] = true;
      //   }
      // }
      //
      // if (!this.formModel.simple.score_pm) {
      //   if (this.formModel.simple.score_pm != '0') {
      //     this.formRules['simple.computing_method'][0]['required'] = true;
      //   }
      // }
      // if (!this.formModel.simple.score_si) {
      //   if (this.formModel.simple.score_si != '0') {
      //     this.formRules['simple.computing_method'][0]['required'] = true;
      //   }
      // }
      // if (!this.formModel.simple.score_bs) {
      //   if (this.formModel.simple.score_bs != '0') {
      //     if (this.formModel.simple.computing_method == 1) {
      //       this.formRules['simple.supplement_type'][0]['required'] = true;
      //     } else if (this.formModel.simple.computing_method == 2) {
      //       this.formRules['simple.supplement_type'][0]['required'] = false;
      //       this.$refs["form"].clearValidate('simple.supplement_type');
      //       this.formModel.simple.supplement_type = '';
      //     }
      //   }
      // }

      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let res = null;

          if (this.id) {
            res = await Update({base: this.formModel.base, assessment: this.formModel.simple});
          } else {
            res = await Save({base: this.formModel.base, assessment: this.formModel.simple});
          }
          if (res && res.code == 0) {
            window.localStorage.removeItem('base_info');
            message.success({content: res.message});
            setTimeout(() => {
              if (this.id == '') {
                this.$router.push({path: "/"});
              } else if (this.id != '' && this.source == 'index') {
                this.$router.push({path: "/"});
                console.log(1)
              } else if (this.id != '' && this.source == 'history') {
                this.$router.push({path: "/maintain-history-report"});
              }

            }, 1500);
          }
        } else {
          message.error({content: '表单验证未通过'});
        }
      });
    },
    async getRealAge(type, time) {
      const res = await Api.GetRealAge({born_time: this.formModel.base.born_date, test_date: time});
      if (res && res.code == 0) {
        console.log(res.realAge);
        if(!this.isAgeGreaterThan7Years11Months(res.realAge)){
          this.scoreCdTip = '<0-65>';
          this.scoreCdMax = 65;
        }else{
          this.scoreCdTip = '<0-119>';
          this.scoreCdMax = 119;
        }

        console.log();
        this.formModel.simple.real_age = res.realAge;
      }
    },
    isAgeGreaterThan7Years11Months(ageStr) {
      // 解析年、月、日
      const agePattern = /(\d+)岁(\d+)个月(\d+)天/;
      const match = ageStr.match(agePattern);
      if (!match) {
        throw new Error("Invalid age format");
      }
      const years = parseInt(match[1], 10);
      const months = parseInt(match[2], 10);
      // 判断年龄是否大于7岁11个月
      if (years > 7) {
        return true;
      } else if (years < 7) {
        return false;
      } else { // years === 7
        return months > 11;
      }
    },


    changeTeachTestTime(e, s) {
      if (s) {
        this.formModel.simple.test_date = s;
        this.getRealAge('teacher', s);
      } else {
        this.formModel.simple.real_age = ''
      }
    },
    changeParentTestTime(e, s) {
      if (s) {
        this.formModel.simple.test_date = s;
        this.getRealAge('parent', s);
      } else {
        this.formModel.simple.real_age = ''
      }
    },
    // 当选择了比例估算法，不用再选择补充方式
    changeCM(e) {
      if (e && e.target.value == '2') {
        this.formRules['simple.supplement_type'][0]['required'] = false;
        this.$refs["form"].clearValidate('simple.supplement_type');
        this.formModel.simple.supplement_type = '';
      } else {
        this.formRules['simple.supplement_type'][0]['required'] = true;
      }
    }
  }
};
</script>
<style></style>
<style scoped>
.main-body {
  background-image: url("/images/maintain-bg.png");
  align-items: flex-start;
  justify-content: flex-start;
}

.line1 {
  border: 1px solid #f8f8f8;
  margin-bottom: 20px;
}

.ant-form-item-control .ant-form-item-children {
  display: flex;
}

.num-de {
  font-size: 12px;
  font-weight: 400;
  color: #999A9A;
  width: 100px;
  text-align: center;
}

.flex {
  display: flex;
}

.right-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  //height: 460px; border-left: 1px solid #eee;
  padding-left: 70px;
  justify-content: center;
}

.right-title {
  font-size: 14px;
  font-weight: 600;
  color: #832C75;
  margin-bottom: 20px;
}

.cu-select {
  line-height: 42px;
  height: 42px;
}

.btn {
  background: #832C75;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
  padding: 0 80px;
}

.btn.sh {
  border: 1px solid #832B75;
  border-radius: 5px;
  font-weight: 400;
  color: #832B75;
  line-height: 45px;
  background: #FFFFFF;
  display: inline;
  display: flex;
  align-items: center;
}

.ant-form-item {
  margin-bottom: 12px !important;
}
</style>
